<template>
  <v-container>
    <v-row dense>
      <v-col cols="auto">
        <gradient-bar />
        <h6 class="primary clipedText mt-4" style="font-size: 19.2">Planos</h6>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        md="6"
        sm="6"
        class="slide-left-to-right"
        style="background-color: transparent !important"
      >
        <v-row dense>
          <h3 class="clipedText primary headerText">Estude como quiser</h3>
        </v-row>
        <v-row no-gutters class="mt-2">
          <span>
            Você é quem decide o tamanho do grupo de estudo, o nível de desafio
            que prefere nas aulas, a meta que deseja alcançar e a frequência com
            que deseja se dedicar. Dessa forma, você desfruta da flexibilidade
            de concluir o curso no seu próprio ritmo, sem sentir pressão ou
            enrolação.
          </span>
        </v-row>
        <v-row dense class="mt-6">
          <span>
            Para iniciar, basta montar o plano que mais se adequa aos seus
            objetivos. Enquanto alguém pode alcançar fluência no idioma em até 5
            anos, se desejar agilizar o processo, pode optar por uma turma
            reduzida e aumentar a frequência dos estudos.
          </span>
        </v-row>
        <v-row dense class="mt-6">
          <span> Lembre-se </span>
        </v-row>
        <v-row dense class="mt-1">
          <span>
            Quanto mais você se dedica, mais rápido você aprende! 😉
          </span>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
        style="background-color: transparent !important"
        class="slide-right-to-left"
      >
        <v-row
          dense
          v-if="$vuetify.breakpoint.smAndUp"
          align="center"
          justify="center"
        >
          <div class="mb-8" ref="animationContainer"></div>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      dense
      style="background-color: transparent !important"
      class="slide-bottom-to-top"
    >
      <v-col cols="12" md="6" sm="6">
        <v-row dense>
          <v-col cols="12" md="12" sm="12" class="mt-6">
            <v-row dense>
              <span class="bolder">Nível:</span>
            </v-row>
            <v-row dense>
              <v-col
                cols="12"
                md="auto"
                sm="auto"
                class="d-flex align-center justify-start"
              >
                <v-btn
                  rounded
                  outlined
                  elevation="2"
                  :class="{
                    primary: level === 'beginner',
                    selected: level === 'beginner',
                  }"
                  @click="setLevel('beginner')"
                  >Iniciante</v-btn
                >
              </v-col>
              <v-col
                cols="12"
                md="auto"
                sm="auto"
                class="d-flex align-center justify-start"
                ><v-btn
                  rounded
                  outlined
                  elevation="2"
                  :class="{
                    primary: level === 'average',
                    selected: level === 'average',
                  }"
                  @click="setLevel('average')"
                  >Intermediário</v-btn
                ></v-col
              >
              <v-col
                cols="12"
                md="auto"
                sm="auto"
                class="d-flex align-center justify-start"
                ><v-btn
                  rounded
                  outlined
                  elevation="2"
                  :class="{
                    primary: level === 'advanced',
                    selected: level === 'advanced',
                  }"
                  @click="setLevel('advanced')"
                  >Avançado</v-btn
                ></v-col
              >
            </v-row>
            <v-row dense class="mt-6">
              <v-col cols="12" md="auto" sm="auto" xs="auto">
                <v-row dense>
                  <span class="bolder">Modalidade:</span>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="auto" sm="auto">
                    <v-btn
                      rounded
                      outlined
                      elevation="2"
                      :class="{
                        primary: format === 'individual',
                        selected: format === 'individual',
                      }"
                      :disabled="level === null"
                      @click="setFormat('individual')"
                      >Individual</v-btn
                    >
                  </v-col>
                  <v-col cols="12" md="auto" sm="auto"
                    ><v-btn
                      rounded
                      outlined
                      elevation="2"
                      :class="{
                        primary: format === 'duo',
                        selected: format === 'duo',
                      }"
                      :disabled="level === null"
                      @click="setFormat('duo')"
                      >Dupla</v-btn
                    ></v-col
                  >
                  <v-col cols="12" md="auto" sm="auto"
                    ><v-btn
                      rounded
                      outlined
                      elevation="2"
                      :class="{
                        primary: format === 'trio',
                        selected: format === 'trio',
                      }"
                      :disabled="level === null"
                      @click="setFormat('trio')"
                      >Trio</v-btn
                    ></v-col
                  >
                  <v-col cols="12" md="auto" sm="auto"
                    ><v-btn
                      rounded
                      outlined
                      elevation="2"
                      :class="{
                        primary: format === 'squad',
                        selected: format === 'squad',
                      }"
                      :disabled="level === null"
                      @click="setFormat('squad')"
                      >Quarteto</v-btn
                    ></v-col
                  >
                  <v-col cols="12" md="auto" sm="auto">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          rounded
                          outlined
                          elevation="2"
                          :class="{
                            primary: format === 'by_myself',
                            selected: format === 'by_myself',
                          }"
                          :disabled="level === null"
                          @click="setFormat('by_myself')"
                          >Conversation</v-btn
                        >
                      </template>
                      <span class="ml-2">
                        Acesso às aulas de conversação e disponibilidade de
                        material didático e benefícios de acordo com o nível
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense class="mt-6">
              <v-col cols="12" md="6" sm="6">
                <v-row dense>
                  <span class="bolder">Tempo de Aula:</span>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="auto" sm="auto"
                    ><v-btn
                      rounded
                      outlined
                      elevation="2"
                      :class="{
                        primary: time === '1h',
                        selected: time === '1h',
                      }"
                      :disabled="format === null || format === 'by_myself'"
                      @click="setTime('1h')"
                      >50 min</v-btn
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
            <v-row dense class="mt-6">
              <v-col cols="12" md="12" sm="12">
                <v-row dense>
                  <span class="bolder">Frequência:</span>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                    md="auto"
                    sm="auto"
                    class="d-flex align-center justify-start"
                    ><v-btn
                      rounded
                      outlined
                      elevation="2"
                      :class="{
                        primary: frequency === '1',
                        selected: frequency === '1',
                      }"
                      :disabled="time === null || format === 'by_myself'"
                      @click="setFrequency('1')"
                      >x1 na semana</v-btn
                    ></v-col
                  >
                  <v-col
                    cols="12"
                    md="auto"
                    sm="auto"
                    class="d-flex align-center justify-start"
                    ><v-btn
                      rounded
                      outlined
                      elevation="2"
                      :class="{
                        primary: frequency === '2',
                        selected: frequency === '2',
                      }"
                      :disabled="time === null || format === 'by_myself'"
                      @click="setFrequency('2')"
                      >x2 na semana</v-btn
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" sm="6" class="d-flex align-center justify-center">
        <v-row dense align="center" justify="center" ref="priceCard">
          <v-card elevation="0" style="background-color: transparent">
            <v-card-text :disabled="disabledCard">
              <v-row dense>
                <v-col cols="12" md="12" sm="12" class="mt-1">
                  <v-row dense align="center" justify="start">
                    <h1
                      style="font-family: inter, sans-serif; font-size: 44px"
                      :style="{ color: price ? '#e8276f' : '#cdcdd1;' }"
                    >
                      {{ price }}/mês
                    </h1>
                  </v-row>
                  <v-row dense align="center" justify="start" class="mt-4">
                    <v-icon v-if="!isIndividual">mdi-clock-outline</v-icon>
                    <span class="ml-2" v-if="!isIndividual"
                      >Duração estimada {{ estimatedPeriod }}</span
                    >
                  </v-row>
                  <v-row dense align="center" justify="start" class="mt-4">
                    <v-btn
                      rounded
                      outlined
                      elevation="2"
                      :disabled="price === 0"
                      :class="{
                        primary: !disabledCard,
                        selected: !disabledCard,
                      }"
                      @click="openConversation"
                      >Escolher plano</v-btn
                    >
                  </v-row>
                  <v-row dense align="center" justify="start" class="mt-4">
                    <v-btn icon @click="download" :disabled="disabledCard">
                      <v-img
                        :src="downloadButton"
                        max-width="24px"
                        max-height="24px"
                      />
                    </v-btn>
                    <v-btn
                      plain
                      small
                      @click="download"
                      :disabled="disabledCard"
                      :style="{ color: !disabledCard ? '#e8276f' : '#CDCDD1' }"
                      >Ementa do curso</v-btn
                    >
                  </v-row>
                  <v-row dense align="center" justify="start" class="mt-4">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row dense align="center" justify="start" class="mt-4">
                    <span class="bolder">Beneficios</span>
                  </v-row>

                  <div v-if="!isIndividual">
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Material Grátis</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Portal do aluno</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Grupo de estudos</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Certificado</span>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">App</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Plataforma de estudos</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Vídeo aulas</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Eventos</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Guia de estudos</span>
                    </v-row>
                    <v-row dense align="center" justify="start" class="mt-4">
                      <v-icon color="primary">mdi-check-circle-outline</v-icon>
                      <span class="ml-2">Monitorias</span>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import lottie from "lottie-web";
const animationData = require("@/assets/animations/plans/learning.json");
export default {
  name: "PlansSection",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      level: null,
      format: null,
      time: null,
      frequency: null,
      price: 0.0,
      disabledCard: true,
      estimatedPeriod: null,
      isIndividual: false,
    };
  },
  computed: {
    downloadButton() {
      return this.disabledCard
        ? require("@/assets/icons/download.svg")
        : require("@/assets/icons/download_pink.svg");
    },
  },
  watch: {
    frequency(value) {
      if (value && this.level && this.format && this.time) {
        this.calculatePrice();
        this.disabledCard = false;
        this.estimatedPeriod = this.setDutation();
      }
    },
    format() {
      this.calculatePrice();
      this.estimatedPeriod = this.setDutation();
    },
    time() {
      this.calculatePrice();
      this.estimatedPeriod = this.setDutation();
    },
    price() {
      if (this.$vuetify.breakpoint.xsOnly) {
        scrollTo(0, this.$refs.priceCard.offsetTop + 4800);
      }
    },
  },
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.animationContainer,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    });
  },
  methods: {
    setLevel(level) {
      this.level = level;
    },
    setFormat(format) {
      if (format === "by_myself") {
        this.time = null;
        this.frequency = null;
      }
      this.format = format;
    },
    setTime(time) {
      this.isIndividual = false;
      this.time = time;
    },
    setFrequency(frequency) {
      this.frequency = frequency;
    },
    download() {
      window.open(process.env.VUE_APP_PITCH_EMENTA, "_blank");
    },
    calculatePrice() {
      if (this.format === "by_myself") {
        this.disabledCard = false;
        this.setPrice(79.9);
        this.isIndividual = true;
      } else if (this.format && this.time && this.frequency && this.level) {
        this.isIndividual = false;
        switch (this.time) {
          case "1h":
            switch (this.format) {
              case "individual":
                if (this.frequency === "1") this.setPrice(263.9);
                else this.setPrice(527.9);
                break;
              case "duo":
                if (this.frequency === "1") this.setPrice(173.9);
                else this.setPrice(257.9);
                break;
              case "trio":
                if (this.frequency === "1") this.setPrice(143.9);
                else this.setPrice(247.9);
                break;
              case "squad":
                if (this.frequency === "1") this.setPrice(123.9);
                else this.setPrice(207.9);
                break;
            }
            break;
        }
      }
    },
    setPrice(value) {
      this.price = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    openConversation() {
      this.format === "by_myself"
        ? window.open(
            `https://api.whatsapp.com/send?phone=${this.whatsApp()}&text=*Gostaria de me inscrever para começar as aulas de inglês! 🚀*
            > 📚 Nível: ${this.levelToPt()}.
            > 🎓 Formato de Aula: ${this.typeToPT()}.
            > 💵 Mensalidade: ${this.price}.
        `,
            "_blank"
          )
        : window.open(
            `https://api.whatsapp.com/send?phone=${this.whatsApp()}&text=*Gostaria de me inscrever para começar as aulas de inglês! 🚀*
        > 📚 Nível: ${this.levelToPt()}.
        > 🎓 Formato de Aula: ${this.typeToPT()}. 
        > ⏰ Tempo de Aula: Aulas de 50 minutos.
        > 🔄 Frequência: ${this.frequencyToPT()} vez por semana.
        > 💵 Mensalidade: ${this.price}.
        > ⏳ Duração Estimada: Entre ${this.estimatedPeriod}`,
            "_blank"
          );
    },
    levelToPt() {
      switch (this.level) {
        case "beginner":
          return "Iniciante";
        case "average":
          return "Intermediário";
        case "advanced":
          return "Avançado";
      }
    },
    typeToPT() {
      switch (this.format) {
        case "individual":
          return "Individual";
        case "duo":
          return "Dupla";
        case "trio":
          return "Trio";
        case "squad":
          return "Quarteto";
        case "by_mysqlf":
          return "Conversation";
      }
    },
    frequencyToPT() {
      switch (this.frequency) {
        case "1":
          return "Uma";
        case "2":
          return "Duas";
      }
    },
    whatsApp() {
      return process.env.VUE_APP_PITCH_WPP.replaceAll(" ", "").replaceAll(
        "-",
        ""
      );
    },
    setDutation() {
      switch (this.level) {
        case "beginner":
          switch (this.format) {
            case "individual":
              if (this.frequency === "1") return "3.0 - 3.5 anos.";
              else return "1.5 - 2.0 anos.";
            case "duo":
              if (this.frequency === "1") return "3.5 - 4.5 anos.";
              else return "2.0 - 2.5 anos.";
            case "trio":
              if (this.frequency === "1") return "4.5 - 5.5 anos.";
              else return "2.5 - 3.0 anos.";
            case "squad":
              if (this.frequency === "1") return "5.5 - 6.5 anos.";
              else return "3.0 - 3.5 anos.";
          }
          break;
        case "average":
          switch (this.format) {
            case "individual":
              if (this.frequency === "1") return "2.0 - 2.5 anos.";
              else return "1.0 - 1.5 anos.";
            case "duo":
              if (this.frequency === "1") return "2.0 - 3.5 anos.";
              else return "1.0 - 2.0 anos.";
            case "trio":
              if (this.frequency === "1") return "2.5 - 4.0 anos.";
              else return "1.5 - 2.0 anos.";
            case "squad":
              if (this.frequency === "1") return "3.5 - 5.0 anos.";
              else return "1.5 - 2.0 anos.";
          }
          break;
        case "advanced":
          switch (this.format) {
            case "individual":
              if (this.frequency === "1") return "1.0 - 1.5 anos.";
              else return "0.5 - 1.0 anos.";
            case "duo":
              if (this.frequency === "1") return "1.0 - 2.0 anos.";
              else return "0.5 - 1.0 anos.";
            case "trio":
              if (this.frequency === "1") return "1.5 - 2.5 anos.";
              else return "1.0 - 1.5 anos.";
            case "squad":
              if (this.frequency === "1") return "2.0 - 3.0 anos.";
              else return "1.0 - 1.5 anos.";
          }
          break;
      }
    },
  },
};
</script>
<style scoped>
.headerText {
  font-size: 45px;
  white-space: normal;
  font-style: bold;
  overflow: visible;
  padding: 5px;
}

.clipedText {
  background-clip: text;
  color: transparent;
}

.menuLinear {
  width: 97px;
  background-color: linear-gradient(to right, #9923cd, #e8276f);
}

.bolder {
  font-weight: bolder;
}

.selected {
  color: white !important;
}
</style>
